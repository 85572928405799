
.auth-modal-card
  position: relative
  border-radius: 15px
  width: 800px
  max-width: 800px !important
  @media screen and (max-width: 1023px)
    padding-bottom: 50px
    box-shadow: none !important
    margin-top: 58px !important
    width: 100vw !important
    max-width: 100vw !important
.authModal
  .q-dialog__inner--minimized > div
    max-height: 700px
    max-width: 750px !important
    overflow: hidden
  .q-field--filled .q-field__control
    border-radius: 5px !important
    border: 1px solid #D2D2D2
.login-btn, .facebook-btn
  font-weight: 600
  font-size: 17px
  padding: 22px 20px
  text-transform: uppercase
.login-btn
  .q-btn__wrapper
    padding: 0 !important
  color: white
  background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
.facebook-btn
  background: #3B5998
.forgotten-password
  width: 100%
  background: transparent
  font-size: 17px
  font-weight: 400
.login-input
  font-size: 17px
  .q-field__control
    height: 60px
    border-radius: 8px
  .q-field__control:before
    border: 1px solid transparent
    box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
    -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
.login-input > * > .q-field__control
  background: #F2F2F2
  border: none
