
.similar-products-carousel
  display: flex
  flex-direction: row
  .carousel__slide
    align-items: baseline
  .q-rating__icon
    text-shadow: none
  .carousel__viewport, .carousel__track
    height: 100%
  .carousel__next
    right: 10px
  .carousel__prev
    left: 10px
  .carousel__prev, .carousel__next
    .carousel__icon
      color: white
    cursor: pointer
    background: #4D4F53
    border-radius: 100px
    width: 40px
    height: 40px
    display: flex
    justify-content: center
    align-items: center
    z-index: 1
    position: absolute
    top: 50%
    transition: background-color 0.33s ease-in-out
    &:hover
      background: darkgray
.related-products-title
  display: flex
  color: #4D4F53
  font-size: 20px
  flex-direction: row
  font-weight: 400
  width: 100%
  margin: 16px 0
.related-products-title:before,
.related-products-title:after
  content: ""
  flex: 1
  border-bottom: 1px solid #EAEAEA
  margin: auto
.related-products-title:before
  margin-right: 2%
.related-products-title:after
  margin-left: 2%
.scroll-area > * > .absolute > :last-child > div > div > .popup-item
  padding-bottom: 8px !important
.scroll-area > * > .absolute > div:last-child > .full-width > .full-width:last-child > div > hr
  display: none
.gradientLine
  background: rgba(241,34,42,1)
  background: -moz-linear-gradient(90deg, rgba(241,34,42,1) 0%, rgba(16,108,235,1) 100%)
  background: -webkit-linear-gradient(90deg, rgba(241,34,42,1) 0%, rgba(16,108,235,1) 100%)
  background: linear-gradient(90deg, rgba(241,34,42,1) 0%, rgba(16,108,235,1) 100%)
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
  padding: 10px 10px
  display: flex
  justify-content: center
  align-items: center
  width: 100%
.separator
  background: #F2F2F2
  height: 2px
  margin: 0 15px
.mainTitle
  font-size: 22px
  color: #4D4F53
  font-weight: 600
  margin-left: 10px
  @media screen and (max-width: 1000px)
    font-size: 20px
.gradientTitle, .gradientPrice
  font-size: 18px
  @media screen and (max-width: 1023px)
    font-size: 14px !important
  color: #F7F6F9
.gradientTitle
  font-weight: 400
.gradientPrice
  font-weight: 600
.review-rating > .q-icon
  text-shadow: none !important
.priceTitle
  color: #4D4F53
  font-size: 14px
.toBasketBtn
  width: 100px
  margin-top: 5px
  padding: 0 20px 0 20px
  border-radius: 8px
  box-shadow: 0 3px 2px 0 rgba(223,224,227,1)
  -webkit-box-shadow: 0 3px 2px 0 rgba(223,224,227,1)
  -moz-box-shadow: 0 3px 2px 0 rgba(223,224,227,1)
  display: flex
  align-items: center
  justify-content: center
  font-weight: 600
  font-size: 16px
  color: #4d4f53
  background: #ECECEE
  height: 30px
  cursor: pointer
.similarProduct
  transition: all 0.33s ease-in-out
  &:hover
    transform: translateY(-3px)
    background-color: white
.carousel
  height: 100%
  width: 100% !important
.slideWidth
  min-width: 25% !important
  @media screen and (max-width: 1020px)
    min-width: 50% !important
  @media screen and (max-width: 599px)
    min-width: 50% !important
.scrollAreaContainer
  width: 100%
  padding: 10px 18px 10px 18px
  margin-bottom: 10px
  @media screen and (max-width: 1023px)
    padding: 0 !important
    margin-bottom: 0px !important
.q-dialog__inner--minimized > div
 max-width: 750px !important
