
.listItem
  cursor: pointer
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  padding: 10px 15px 10px 35px
  .label
    font-size: 16px
    font-weight: 500
