
.chip
  border: 2px solid rgb(50, 50, 50)
  background: transparent !important
.chip-sm
  border: 1px solid rgb(50, 50, 50)
  background: transparent !important
.quantityCircle
  border: 2px solid #55575B
  border-radius: 56px
  background: white
  padding: 5px 10px 5px 10px
  font-size: 16px
  display: flex
  justify-content: center
  align-items: center
  color: #55575B
  font-weight: 500
.popupItemName
  font-weight: 500
  color: #4D4F53
  line-height: 17px
  font-size: 16px
.normalPrice
  font-size: 16px !important
  color: #4d4f53
  font-weight: 500
  text-align: right
.small-cart-quantity
  color: #4d4f53
  text-align: right
  font-weight: 400
  font-size: 16px
.cart-item-taste, .cart-item-quantity
  font-weight: 400
  color: #4D4F53
.bigCloseBtn
  z-index: 1000
  cursor: pointer
  background: #4E4F53
  height: 25px
  width: 25px
  border-radius: 100px
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  top: 0px
  left: -5px
