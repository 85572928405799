
.fast-purchase-wrapper
  min-height: 650px
  width: 700px
  background: white
  border-radius: 12px !important
  padding: 0 24px
  .fast-purchase-header
    padding: 20px 10px
    color: #4D4F53
    font-size: 22px
    font-weight: 600
    display: flex
    flex-direction: row
    align-items: center
    position: relative
    .close-btn
      position: absolute
      right: 0
      top: 16px
      cursor: pointer
  .fast-purchase-body
    padding: 20px 0
    display: flex
    flex-direction: row
    @media screen and (max-width: 599px)
      flex-direction: column !important
    .img-wrapper
      display: flex
      align-items: center
      justify-content: center
      background: #E3E3E3
      height: 200px
      width: 200px
      @media screen and (max-width: 599px)
        align-self: center
    .product-wrapper
      padding: 10px 0 0 20px
      flex: 1
      @media screen and (max-width: 599px)
        padding: 10px 0 0 0 !important
      .product-description
        .product-title
          font-size: 24px
          color: #4D4F53
          font-weight: 600
          line-height: 1.2
          padding-bottom: 10px
        .rating-wrapper
          display: flex
          flex-direction: row
          align-items: center
          padding-bottom: 16px
          .product-rating
            margin-right: 4px
            color: #0370F7
          .rating-count
            color: #4D4F53
        .product-taste, .product-package-sizes, .product-quantity
          color: #4D4F53
          font-size: 15px
          font-weight: 500
          padding-bottom: 6px
        .drop-down-btn
          border-radius: 8px
          color: #4D4F53
          font-size: 16px
          height: 50px
          margin-bottom: 16px
          background: #ECECEE
          width: 100%
          box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -o-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          i
            font-size: 20px !important
            color: black !important
          .q-btn__content
            justify-content: space-between
        .quantity-to-purchase
          display: flex
          flex-direction: row
          align-items: center
          justify-content: space-between
          padding-bottom: 20px
          .quantity-count
            min-width: 60px
            display: flex
            align-items: center
            justify-content: center
            font-size: 20px
            color: #4D4F53
          .increment, .decrement
            height: 50px
            width: 50px
            border-radius: 8px
            cursor: pointer
            background: #ECECEE
            box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
            -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
            -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
            -o-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          .product-price
            font-size: 20px
            color: #4D4F53
            font-weight: 600
        .to-basket, .additional-info
          width: 100%
          border-radius: 8px
          font-size: 18px
          height: 60px
          font-weight: 600
        .to-basket
          background: #0470F7
          color: white
          margin-bottom: 16px
          svg
            margin-right: 10px
        .additional-info
          background: #ECECEE
          color: #4D4F53
          box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -webkit-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -moz-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
          -o-box-shadow: 0 2px 2px 0 rgba(223,224,227,1)
.taste-selector, .quantity-selector
  height: 50px
  align-items: center
