
.chip
  border: 2px solid rgb(50, 50, 50)
  background: transparent !important
.chip-sm
  border: 1px solid rgb(50, 50, 50)
  background: transparent !important
.gift-selector
  min-height: 70px
  background: linear-gradient(90deg, #106ceb 0%, #f1222a 100%)
  border-radius: 8px
  color: white
  padding: 20px
  @media screen and (max-width: 1023px)
    padding: 12px !important
.chooseLabel
  font-weight: 500
  font-size: 16px
  color: #4d4e53
.productGiftContainer
  .extra-products
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: 12px
    justify-content: space-between
    @media screen and (max-width: 599px)
      margin-bottom: 6px !important
  width: 100%
  margin-top: 12px
  margin-bottom: 20px
  @media screen and (max-width: 1023px)
    margin-bottom: 12px !important
.selectedGiftsContainer
  padding-bottom: 20px
  @media screen and (max-width: 1023px)
    padding-bottom: 0px !important
