
.footerSize
  @media screen and (min-width: 1024px)
    padding: 0 16px !important
.contactTitle
  font-size: 16px
  font-weight: 500
.footerContainer
  ul
   list-style: none
   color: #a6a6a6
   font-size: 16px
   padding: 0
   li
     display: flex
     align-items: center
     margin: 10px 0
     a
      color: #4D4E52
      text-decoration: none
.svg-icon
  width: 20px !important
  height: 20px !important
