
.skeleton-wrapper
  display: flex
  flex-direction: row
  width: 100%
  padding-bottom: 16px
  .skeleton-img, .skeleton-text
    background: #ECECEE
  .skeleton-text-container
    display: flex
    flex-direction: column
    flex: 1
    padding-left: 16px
    padding-top: 6px
