
.q-dialog--seamless
  .q-dialog__inner, .q-dialog__inner--minimized
    padding: 0 !important
    max-height: 100% !important
.selectItemModal
  width: 560px
  .scrollAreaWrapper
    @media screen and (min-width: 1024px)
      padding: 0 20px
  .item-select-title
    font-size: 32px
    padding: 20px 0
    font-weight: 600
    @media screen and (max-width: 600px)
      font-size: 22px !important
      align-self: center
      padding: 10px 0 !important
  @media screen and (max-width: 600px)
    width: initial !important
  .item-list
    border-radius: 5px !important
    padding: 15px 20px
    min-height: 60px
    @media screen and (max-width: 600px)
      margin: 6px !important
  .scroll-item
    padding: 0 12px 20px 12px
    @media screen and (max-width: 1024px)
      padding-bottom: 45px
    .q-card
      cursor: pointer
      margin-bottom: 16px
      margin-right: 16px
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      flex-wrap: nowrap
  .scroll-item > .scroll
    align-items: start !important
  .text-color
    color: #4D4F53
  .box
    width: 35px
    height: 35px
    border-radius: 100px
    background: #F2F2F2
    border: 1px solid #D8D8D8
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    box-shadow: 0px 1px 1px 0px rgba(223,224,227,1)
    -webkit-box-shadow: 0px 1px 1px 0px rgba(223,224,227,1)
    -moz-box-shadow: 0px 1px 1px 0px rgba(223,224,227,1)
  .selectedItem
    background: #D2E1FA
  .selectedBox
    width: 35px
    height: 35px
    border-radius: 100px
    background: #D2E1FA
    border: 2px solid #0370F7
    cursor: pointer
    box-shadow: 0 1px 1px 0 rgba(223,224,227,1)
    -webkit-box-shadow: 0 1px 1px 0 rgba(223,224,227,1)
    -moz-box-shadow: 0 1px 1px 0 rgba(223,224,227,1)
    .innerBox
      width: 21px
      height: 21px
      border-radius: 100px
      background: #0370F7
.selectItemHeader
  height: 80px
  @media screen and (max-width: 1024px)
    height: 50px !important
