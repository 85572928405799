
.search
  .q-field--outlined .q-field__control:before
    border: 2px solid #dedede !important
.container
  .q-menu
    @media screen and (max-width: 1023px)
      max-width: 100vw !important
      max-height: calc(100vh - 55px) !important
    @media screen and (max-width: 1023px)
      width: 100vw !important
      height: 400px
.infinite > :last-child > * > hr
  display: none !important
.searchResultPopupContainer
  .q-field--outlined .q-field__control:hover:before
    border: none
  .q-field--outlined .q-field__control:before
    border: none
  .search-wrapper
    display: flex
    flex-direction: row
    align-items: center
    .search-input
      position: relative
      padding: 10px 6px 10px 10px
      flex: 1
      @media screen and (max-width: 1023px)
        font-size: 16px
        .q-field__marginal
          height: 100%
        .q-field__prepend
          padding-right: 10px !important
          height: 50px
        .q-field__control
          padding-left: 10px
          .q-field__label
            line-height: 14px
            color: #4D4F53 !important
        .q-field__inner
          height: 50px
          .q-field__control
            border-radius: 8px
            -webkit-box-shadow: inset 0 0 0 1px #CACACA
            box-shadow: inset 0 0 0 1px #CACACA
            height: 50px
          .q-field--dense
            height: 50px
    .close-btn
      display: flex
      cursor: pointer
      border-radius: 7px
      padding: 8px
      margin-right: 10px
      background: #4E4F53
  .search-result-title
    display: flex
    flex-direction: row
    padding: 0 16px
    width: 100%
    align-items: center
    height: 40px
    background: #ECECEE
    text-transform: uppercase
    font-weight: 600
    color: #ABABAD
    font-size: 14px
  height: 400px
  background: #FFFFFF
  width: 700px
  max-width: 100vw
  @media screen and (max-width: 1023px)
    width: 100vw !important
    height: 400px
  @media screen and (max-width: 599px)
    width: 100vw !important
    height: 100vh
  .search-result-wrapper
    @media screen and (max-width: 599px)
      height: calc(100vh - 213px)
.mobileCategoriesTitleContainer
  text-transform: uppercase
  font-weight: 500
  background: #ececee
  display: flex
  justify-content: center
  align-items: center
  width: 100%
  height: 40px
  color: #4D4F53
  font-size: 14px
  .label
    color: #4E4F53
    text-transform: uppercase
    font-size: 15px
    font-weight: 600
.mobileCategoriesContainer
  .searchCategoryScrollArea
    height: 100%
  height: 63px
  display: flex
  flex-direction: row
  .categoryChip
    align-self: center
    width: auto
    padding: 0 10px
    height: 40px
    background: #ececee
    border-radius: 100px
    display: flex
    align-items: center
    justify-content: center
    margin: 0 4px
    .label
      color: #4E4F53
      text-transform: uppercase
      font-size: 14px
      font-weight: 600
