
.search-result-item
  cursor: pointer
  width: 100%
  .q-item--dense
    padding: 10px 16px
  .product-name
    color: #4D4F53
    font-size: 14px
    font-weight: 500
    line-height: 1.1
  .product-category
    font-size: 10px
    color: #4D4F53
  .number-of-tastes
    font-size: 14px
    color: #4D4F53
.product-list-item
  position: relative
  background: #dfe0e3
.fast-purchase
  font-family: 'Work Sans', sans-serif
  bottom: 5%
  right: 5%
  min-width: 150px
  color: #4d4f53
.price-size
  font-size: 18px
  font-weight: 500
  color: #4E4F53
  @media screen and (max-width: 599px)
    padding-right: 6px
.smallerQuantitySize
  @media screen and (max-width: 599px)
    font-size: 10px !important
  @media screen and (min-width: 600px)
    font-size: 14px !important
.quantity-size
  @media screen and (max-width: 599px)
    font-size: 12px
  @media screen and (min-width: 600px)
    font-size: 16px
  font-weight: 400
  color: #B5B5B7
  line-height: 1
.product-card
  border-radius: 4px
  z-index: 0
  border: 1px transparent solid
  transition: all 0.33s ease-in-out
  &:hover
    transform: translateY(-3px)
    background-color: white
.giftTitle
  font-size: 16px
  color: #7E7E80
  margin-left: 3px
  @media screen and (max-width: 600px)
    font-size: 12px
.productListItemName
  width: 100%
  margin-top: 8px
  cursor: pointer
  display: flex
  align-items: center
  text-align: left
  color: #4E4F51
  font-size: 18px
  line-height: 1.1
  flex: 1 1 auto
  font-weight: 400
