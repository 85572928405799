
.mobileMenuContainer
  background: white
  width: 85vw
  max-width: 320px
  height: 100vh
  .menuItemsContainer
    padding: 25px
    .menuItem
      display: flex
      flex-direction: row
      align-items: center
      .label
        margin-left: 15px
        font-size: 18px
        color: #47484C
        font-weight: 500
