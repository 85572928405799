
.expansion
  text-transform: capitalize
.productName
  font-size: 20px
  color: #4E4F53
  font-weight: 600
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  padding: 0 40px 0 40px
  line-height: 22px
.productCategory
  font-size: 18px
  color: #4E4F53
  font-weight: 500
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  text-align: center
