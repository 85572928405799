
.gift-select-dialog
  .select-gift-scrollarea
    width: 100%
    padding-left: 24px
    padding-right: 24px
    @media screen and (max-width: 600px)
      padding: 0
  .select-gift-icon
    margin-right: 16px
    @media screen and (max-width: 600px)
      margin-left: 16px !important
      margin-right: 12px !important
  .select-gift-wrapper
    display: flex
    width: 100%
    flex-direction: row
    height: 85px
    padding-left: 40px
    align-items: center
    position: relative
    @media screen and (max-width: 600px)
      padding: 16px 20px 16px 0 !important
      height: 65px !important
  .title-wrapper
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    @media screen and (max-width: 600px)
      justify-content: left !important
  .gift-select-title
    font-size: 24px
    @media screen and (max-width: 600px)
      font-size: 18px !important
      font-weight: 500 !important
    @media screen and (max-width: 350px)
      font-size: 16px !important
  .to-basket
    width: 100%
    font-size: 20px
    background: rgb(16,108,235)
    background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
.scroll
  align-items: start
.selectModal
  background: #FFFFFF
  width: 600px
  @media screen and (max-width: 600px)
    width: initial
.gift-selected-bg
  background: #E7EFFD
.gift-chip
  align-self: center
  color: white
  margin: 0 16px
  padding: 2px 14px !important
  background: #F0232B
  font-weight: 600
  font-size: 17px
  border-radius: 30px !important
.linear-progress
  width: 100%
  .q-linear-progress__track
    background: #EBEBED
  .q-linear-progress__model--determinate
    background: linear-gradient(90deg, #0370F7 0, #F0232B 100%) !important
.gift-quantity
  min-width: 60px
  font-size: 22px
  @media screen and (max-width: 600px)
    min-width: 50px !important
    font-size: 18px
  @media screen and (max-width: 355px)
    min-width: 30px !important
    font-size: 18px
.giftName
  font-weight: 600
  color: #4D4F53
  font-size: 18px
  line-height: 20px
  width: 100%
  overflow: hidden
  max-width: 100vw !important
  @media screen and (max-width: 1024px)
    font-weight: 500
    max-width: calc(100vw - 200px) !important
    font-size: 16px
