
.extra-product
  cursor: pointer
  margin-right: 10px
  background: linear-gradient(90deg, #E5F0FE, #FFE8E8)
  .extra-product-text
    font-weight: 700
    text-transform: uppercase
    white-space: nowrap
    padding: 2px 8px
    background: -webkit-linear-gradient(0deg, #0370F7, #FB2C2F 80%)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
    font-size: 14px
    @media screen and (max-width: 1000px)
      font-size: 12px
.chip
  border: 2px solid rgb(50, 50, 50)
  background: transparent !important
.chip-sm
  border: 1px solid rgb(50, 50, 50)
  background: transparent !important
.productGiftName
  font-size: 16px
  font-weight: 500
  color: #4D4F53
  @media screen and (min-width: 104px)
    line-height: 17px !important
  @media screen and (max-width: 1023px)
    line-height: 1.3 !important
.quantityCircle
  border: 2px solid #55575B
  border-radius: 56px
  background: white
  padding: 5px 10px 5px 10px
  font-size: 16px
  display: flex
  justify-content: center
  align-items: center
  color: #55575B
  font-weight: 500
.normalPrice
  font-size: 16px !important
  color: #4d4f53
  font-weight: 500
  text-align: right
.productPageName
  font-size: 16px
  line-height: 1.3 !important
.small-cart-quantity
  color: #4d4f53
  text-align: right
  font-weight: 400
  font-size: 16px
.cart-item-taste, .cart-item-quantity
  font-weight: 400
  color: #4D4F53
