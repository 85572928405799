
.small-cart
  border: 1px solid #e5e6e9
  box-shadow: none !important
  -moz-box-shadow: none !important
  -o-box-shadow: none !important
  -webkit-box-shadow: none !important
.smallCartContainer
  .skeleton-list
    display: flex
    flex-direction: column
    justify-content: space-between
    @media screen and (min-width: 600px)
      min-width: 400px
  .purchase-now
    color: white
    font-weight: 600
    font-size: 20px
    width: 100%
    height: 75px
    border-radius: 0
    background: rgba(16,108,235,1)
    background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1)
  .small-cart-title
    display: flex
    flex-direction: row
    padding: 0 16px
    width: 100%
    align-items: center
    height: 40px
    background: #ECECEE
    text-transform: uppercase
    font-weight: 600
    color: #ABABAD
    font-size: 14px
  .small-cart-scroll-area
    width: 400px
    @media screen and (max-width: 599px)
      width: 100vw !important
    @media screen and (min-width: 600px)
      max-height: 330px
  @media screen and (max-width: 1023px)
    height: 100%
  @media screen and (max-width: 599px)
    width: 100% !important
    background: white
  .small-cart-wrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: 16px
    height: 80px
    @media screen and (max-width: 599px)
      padding: 16px 10px !important
    .small-cart-total
      font-weight: 600
      color: #4D4F53
      font-size: 20px
      @media screen and (max-width: 320px)
        font-size: 16px !important
  .close-btn
    display: flex
    cursor: pointer
    border: solid #E4E4E4 2px
    border-radius: 7px
    padding: 8px
    margin-left: 6px
  .small-cart-btn
    height: 50px
    padding: 10px
    color: white
    font-weight: 600
    font-size: 15px
    border-radius: 7px
    background: #0370F7
    @media screen and (max-width: 599px)
      padding: 14px 14px
      font-size: 13px !important
      background: #0370F7 !important
      background: -moz-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%) !important
      background: -webkit-linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%) !important
      background: linear-gradient(90deg, rgba(16,108,235,1) 0%, rgba(241,34,42,1) 100%) !important
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#106ceb",endColorstr="#f1222a",GradientType=1) !important
