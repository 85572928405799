
.menuItem
  height: 100%
  .q-menu
    background: transparent !important
    box-shadow: none !important
    -moz-box-shadow: none !important
    -o-box-shadow: none !important
    -webkit-box-shadow: none !important
    @media screen and (max-width: 350px)
      width: 300px !important
.popup-wrapper
  box-shadow: none !important
  background: #FFFFFF
  max-width: 100%
  margin-top: 0
  @media screen and (max-width: 599px)
    border-radius: 0
    margin-top: 58px
    position: absolute
    top: 0
    z-index: 2
.blank-space
  height: 300px
  width: 100%
  box-shadow: none !important
  position: absolute
  top: 63% !important
  background: rgba(0, 0, 0, 0.3)
  z-index: 1
